import { merge } from 'lodash';
import type { AugmentedStore } from '@model/vue-store/augmented-store';

export default {
    namespaced: true,

    state: () => ({
        items: {},
    }),

    getters: {
        getLocales(state) {
            if (!state.items['s-locale-selector']) return [];
            return state.items['s-locale-selector']?.columns?.flatMap(
                (column) => column.regions.flatMap((region) => region.locales),
            );
        },
    },

    actions: {
        async getSyncedData(this: AugmentedStore, store, args) {
            const { slice, locale } = args;
            const response = await this.$context.Synced.slicesData.query(slice, locale);
            const translations = response?.translations || {};
            const data = merge({}, response, translations);

            return store.commit('setSyncedData', { slice, data });
        },
    },

    mutations: {
        setSyncedData(state, { slice, data }) {
            state.items[slice] = data;
        },
    },
};
