// TODO: remove this. Creates much more problems than it solves.
// Make serverside to read directly from @utils/env, while clientside to read from @utils/<you-name-it>.
// @utils/<you-name-it> in its turn just gets some data from a pre-rendered DOM node in the <head>.
// Additionally can be routed with webpack/vite rewiring,
// in a manner that every env just reads from @utils/<universal-env>
export default {
    namespaced: true,

    state: () => ({}),

    getters: {
        $config(_state: never, _getters: never, root: any) {
            return root.context.public;
        },
    },
};
