import type { AugmentedStore } from '@model/vue-store/augmented-store';
import type { ActionContext } from 'vuex';

export default {
    namespaced: true,

    state: () => ({
        locale: {},
        locales: [],
    }),

    actions: {
        async getLocaleData(this: AugmentedStore, store: ActionContext<any, any>, args: { locale: string }) {
            const map = await this.$context.Synced.locales.getMap();
            const localeData = map.get(args.locale);
            store.commit('setLocale', localeData);
        },

        async getAll(this: AugmentedStore, store: ActionContext<any, any>) {
            const res = await this.$context.Synced.locales.getList();
            store.commit('setLocales', res);
        },
    },

    mutations: {
        setLocale(state: any, locale: Record<string, any>) {
            state.locale = locale;
        },

        setLocales(state: any, locales: any) {
            state.locales = locales;
        },
    },
};
