import { StatusCodes } from 'http-status-codes';
import { uniq } from 'lodash';
import { ActionContext } from 'vuex';
import { ProductAPIQueryBuilder } from '@api/builders/product';
import getAPIProduct from '@api/product';
import { LOCALE_DEFAULT } from '@model/const/locales';
import type { SSRContext } from '@model/ssr/context';
import type { AugmentedStore } from '@model/vue-store/augmented-store';
import eventUtils from '@utils/events.js';
import { Logger } from '@utils/logger';
import type { RawAxiosRequestConfig } from 'axios';

export default {
    namespaced: true,

    state: () => ({
        eventsFeed: {
            items: [],
        },
    }),

    getters: {
        getEventCards: (state: any) => (events: any, translations: any, locale = LOCALE_DEFAULT) => {
            const { items } = state.eventsFeed;
            return items.map((item: any, i: number) => {
                const eventDetails = eventUtils.getEventCardFields(item, translations, { locale });
                return {
                    ...eventDetails,
                    theme: 'main',
                    link: `//acronis.events${eventDetails.link}`,
                    image: eventDetails.imageId || events.eventImages[i % events.eventImages.length],
                };
            });
        },
    },

    actions: {
        async getFeed(this: AugmentedStore, store: ActionContext<any, any>, args: { limit: number, locale: string }) {
            // ==== Init vars
            const storeID = 'eventsFeed';
            const locale = args.locale;

            const allLocaleMappings = await this.$context.Synced.slicesData.query(
                'events-locale-mappings',
                LOCALE_DEFAULT,
            );

            if (!allLocaleMappings) return;

            const currentLocaleMappings = allLocaleMappings[locale] as any;

            // Calc countries list
            let countries = [];
            {
                const groupsIds: number[] = currentLocaleMappings?.countryGroups ?? [];

                const groupsPromises = groupsIds.map(
                    (groupID: number) => this.$context.Synced.countryGroups.query(groupID.toString()),
                );
                const groups = await Promise.all(groupsPromises);

                const grouped = groups
                    .flatMap((group) => group.countries)
                    .map((country) => country.id);

                const flat = currentLocaleMappings?.countries ?? [];

                countries = uniq([...grouped, ...flat]);
            }

            if (!countries.length) return;

            // ==== Setup client
            const client = getAPIProduct(this.$context as unknown as SSRContext);

            const optsDefault: RawAxiosRequestConfig = {
                validateStatus: (status: number) => status === StatusCodes.OK,
                timeout: process.env.VUE_ENV === 'client' ? 30000 : 6000,
                method: 'GET',
                url: '/api/events/events/',
            };

            // ==== Request cards
            let items = [];

            {
                const req = new ProductAPIQueryBuilder(storeID)
                    .setCustomParam('has-occurrences', { from: new Date().toISOString() })
                    .setCustomParam('has', { countries })
                    .setCustomParam('process-macros', '1')
                    .addMatchesAny('is_on_demand', '<>', '1')
                    .addSort('is_featured', 'desc')
                    .addSort('dynamic_next_occurrence', 'asc')
                    .setPaginate(1, args.limit)
                    .toObject();

                const opts = { ...optsDefault, params: req.params };

                try {
                    const resp = await client.request(opts);
                    items = [...resp.data.data];
                } catch (error) {
                    Logger.error({ error });
                    return;
                }
            }

            if (items.length === args.limit) {
                store.commit('setFeedItems', items);
                return;
            }

            {
                const req = new ProductAPIQueryBuilder(storeID)
                    .setCustomParam('has', { countries })
                    .addMatchesAny('is_on_demand', '=', '1')
                    .addMatchesAll('is_for_partners', '<>', '1')
                    .setCustomParam('process-macros', '1')
                    .addSort('is_featured', 'desc')
                    .addSort('dynamic_last_occurrence', 'desc')
                    .setPaginate(1, args.limit - items.length)
                    .toObject();

                const opts = { ...optsDefault, params: req.params };

                try {
                    const resp = await client.request(opts);
                    items = [...items, ...resp.data.data];
                } catch (error) {
                    Logger.error({ error });
                    return;
                }
            }

            store.commit('setFeedItems', items);
        },
    },

    mutations: {
        setFeedItems(state: any, items: any) {
            state.eventsFeed = { items };
        },
    },
};
