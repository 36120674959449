import type { AugmentedStore } from '@model/vue-store/augmented-store';

export default {
    namespaced: true,

    state: () => ({
        items: [],
    }),

    actions: {
        async getAllJobs(this: AugmentedStore, store) {
            const items = await this.$context.Synced.workdayJobs.getList();
            store.commit('setJobs', items);
            return items;
        },

        async getJobBySlug(this: AugmentedStore, store, slug: string) {
            const map = await this.$context.Synced.workdayJobs.getMap();
            const item = map.get(slug);
            store.commit('setJobs', [item]);
            return item;
        },
    },

    mutations: {
        setJobs(state, items) {
            state.items = items;
        },
    },
};
