import type { AugmentedStore } from '@model/vue-store/augmented-store';

export default {
    namespaced: true,

    state: () => ({
        integrations: [],
        integration: {},
        categories: [],
    }),

    actions: {
        async getIntegrations(this: AugmentedStore, store) {
            const integrations = await this.$context.Synced.solutionsIntegrations.getList();
            store.commit('setIntegrations', integrations);
        },

        async getIntegration(this: AugmentedStore, store, args) {
            const map = await this.$context.Synced.solutionsIntegrations.getMap();
            const integration = map.get(args.slug);
            store.commit('setIntegration', integration);
        },

        async getCategories(this: AugmentedStore, store) {
            const categories = await this.$context.Synced.solutionsCategories.getList();
            store.commit('setCategories', categories);
        },
    },

    mutations: {
        setIntegrations(state, integrations) {
            state.integrations = integrations;
        },

        setIntegration(state, integration) {
            state.integration = integration;
        },

        setCategories(state, categories) {
            state.categories = categories;
        },
    },
};
