import type { AugmentedStore } from '@model/vue-store/augmented-store';

export default {
    namespaced: true,

    state: () => ({
        items: {},
    }),

    actions: {
        async getAward(this: AugmentedStore, store, id) {
            const item = await this.$context.Synced.awards.query(id.toString());
            store.commit('setAward', { id, item });
        },
    },

    mutations: {
        setAward(state, { id, item }) {
            state.items[id] = item;
        },
    },
};
