/*
 * Used to find correct cardId based on currency and price combination
*/

export const tiersMap = {
    258666: {
        tier: 1,
        USD: 250,
        GBP: 175,
        EUR: 250,
        CAD: 350,
        AUD: 350,
        JPY: 30000,
        INR: 21500,
        BRL: 1500,
    },
    258667: {
        tier: 2,
        USD: 500,
        GBP: 350,
        EUR: 500,
        CAD: 700,
        AUD: 700,
        JPY: 60000,
        INR: 43000,
        BRL: 3000,
    },
    258668: {
        tier: 3,
        USD: 1000,
        GBP: 750,
        EUR: 1000,
        CAD: 1500,
        AUD: 1500,
        JPY: 125000,
        INR: 85000,
        BRL: 6000,
    },
    273837: {
        tier: 4,
        USD: 2000,
        GBP: 1500,
        EUR: 2000,
        CAD: 3000,
        AUD: 3000,
        JPY: 250000,
        INR: 170000,
        BRL: 12000,
    },
    273838: {
        tier: 5,
        USD: 4000,
        GBP: 3000,
        EUR: 4000,
        CAD: 6000,
        AUD: 6000,
        JPY: 500000,
        INR: 340000,
        BRL: 24000,
    },
};
