import { RawAxiosRequestConfig } from 'axios';
import { StatusCodes } from 'http-status-codes';
import qs from 'qs';
import { ActionContext } from 'vuex';
import { ProductAPIQueryBuilder } from '@api/builders/product';
import getAPIProduct from '@api/product';
import getAPIService from '@api/service';
import { LOCALE_DEFAULT } from '@model/const/locales';
import { Logger } from '@utils/logger';

const PAGINATE_SIZE = 50;

async function getProductsListPage(context: any, payload: any) {
    const options: RawAxiosRequestConfig = {
        method: 'GET',
        params: payload.params,
        url: '/api/core/products/',
        paramsSerializer: { serialize: (params) => qs.stringify(params, { encodeValuesOnly: true }) },
        validateStatus: (status: number) => status === StatusCodes.OK,
    };

    try {
        const response = await getAPIProduct(context).request(options);
        return response.data;
    } catch (error) {
        Logger.error({ error });
        return {};
    }
}

function mapProduct(product) {
    return {
        ...product,
        parameters: JSON.parse(product.parameters),
    };
}

export default {
    namespaced: true,

    state: () => ({
        items: {},
        g2rating: 0,
        g2reviews: [],
    }),

    actions: {
        async getProducts(store: ActionContext<any, any>, locale: string) {
            let currentPage = 1;
            let totalPages = 1;

            while (currentPage <= totalPages) {
                const currentLocale = locale || LOCALE_DEFAULT;
                const payload = new ProductAPIQueryBuilder('products')
                    .setLocales([currentLocale])
                    .setPaginate(currentPage, PAGINATE_SIZE)
                    .setCustomParam('process-macros', '1')
                    .toObject();

                // eslint-disable-next-line no-await-in-loop
                const res = await getProductsListPage(this.$context, payload);

                res.data
                    .map(mapProduct)
                    .forEach((product) => store.commit('setProduct', product));

                totalPages = res.pagination.pages_total;

                currentPage++;
            }
        },

        async getG2ProductRating(store: ActionContext<any, any>, productId: string) {
            if (!productId) return 0;

            const client = getAPIService(this.$context);
            const options: RawAxiosRequestConfig = {
                method: 'GET',
                url: `/svc/v1/g2/products/${productId}`,
                paramsSerializer: { serialize: (params) => qs.stringify(params, { encodeValuesOnly: true }) },
                validateStatus: (status: number) => status === StatusCodes.OK,
            };

            try {
                const response = await client.request(options);
                const result = response.data.data.rating;

                store.commit('setG2Rating', result);

                return result;
            } catch (error) {
                Logger.error({ error });
                return 0;
            }
        },

        async getG2ProductReviews(store: ActionContext<any, any>, productId: string) {
            if (!productId) return [];

            const client = getAPIService(this.$context);
            const options: RawAxiosRequestConfig = {
                method: 'GET',
                url: `/svc/v1/g2/products/${productId}/reviews`,
                paramsSerializer: { serialize: (params) => qs.stringify(params, { encodeValuesOnly: true }) },
                validateStatus: (status: number) => status === StatusCodes.OK,
            };

            try {
                const response = await client.request(options);
                const result = response.data.data;

                store.commit('setG2Reviews', result);

                return result;
            } catch (error) {
                Logger.error({ error });
                return [];
            }
        },
    },

    mutations: {
        setProduct(state, product) {
            state.items[product.id] = product;
        },
        setG2Rating(state, rating) {
            state.g2rating = rating;
        },
        setG2Reviews(state, reviews) {
            state.g2reviews = reviews;
        },
    },
};
