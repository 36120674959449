import type { AugmentedStore } from '@model/vue-store/augmented-store';

const ROUTES_CONFIG_NAME = 'routes-config';

export default {
    namespaced: true,

    state: () => {
        // WARN: empty state shape can cause reactivity issues on mutation
        const shape = () => ({
            items: [],
        });

        return {
            config: shape(),
        };
    },

    getters: {
        getRoutesConfig(state: any) {
            const config = state.config.items.find((item) => item.key === ROUTES_CONFIG_NAME) || {};
            return config?.data || [];
        },

        getStaticConfigs(state: any) {
            return state.config.items
                .filter((item) => item.key !== ROUTES_CONFIG_NAME)
                .map((item) => item.data);
        },
    },

    actions: {
        async getSyncedData(this: AugmentedStore, store, args) {
            const { key, locale } = args;
            const data = await this.$context.Synced.seoData.query(key, locale);
            store.commit('setSyncedData', { key, data });
        },
    },

    mutations: {
        setSyncedData(state, data) {
            state.config.items.push(data);
        },
    },
};
