import type { AugmentedStore } from '@model/vue-store/augmented-store';
import type { ActionContext } from 'vuex';

export default {
    namespaced: true,

    state: () => ({
        courses: [],
    }),

    actions: {
        async getCourses(this: AugmentedStore, store: ActionContext<any, any>) {
            const courses = await this.$context.Synced.academy.getList();
            store.commit('setCourses', courses);
        },
    },

    mutations: {
        setCourses(state: any, courses: any) {
            state.courses = courses;
        },
    },
};
